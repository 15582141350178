import request from '@/utils/request'

export function loadCarousel(query) {
	return request({
		url: '/system/front/banner/list',
		method: 'get',
		headers: {
			isToken: false
		},
		params: query
	})
}

export function loadCategory(query) {
	return request({
		url: '/exam/front/category/categoryTree',
		method: 'get',
		headers: {
			isToken: false
		},
		params: query
	})
}

//自定义分类
export function loadClassify(query) {
	return request({
		url: '/exam/front/classify/classifyTree',
		method: 'get',
		headers: {
			isToken: false
		},
		params: query
	})
}


export function loadClass(query) {
	return request({
		url: '/exam/front/class/classList',
		method: 'get',
		headers: {
			isToken: false
		},
		params: query
	})
}

export function loadClassDetail(classId) {
	return request({
		url: '/exam/front/class/classDetail/' + classId,
		headers: {
			isToken: false
		},
		method: 'get'
	})
}

export function loadClassCourseList(query) {
	return request({
		url: '/exam/front/class/classDetail/courseList',
		method: 'get',
		headers: {
			isToken: false
		},
		params: query
	})
}
//加载课程具体章节的详情
export function loadCourseLessonDetail(lessonId) {
	return request({
		url: '/exam/front/course/courseLessonsDetail/' + lessonId,
		headers: {
			isToken: true
		},
		method: 'get'
	})
}

// 新增用户评论
export function addComments(data) {
  return request({
    url: '/exam/front/comments/addComments',
	headers: {
		isToken: true
	},
    method: 'post',
    data: data
  })
}
//加载评论
export function loadComments(query) {
	return request({
		url: '/exam/front/comments/commentsList',
		method: 'get',
		headers: {
			isToken: false
		},
		params: query
	})
}

// 新增课程实时进度
export function addLearningRecord(data) {
  return request({
    url: '/exam/front/course/courseLearning/addLearningRecord',
	headers: {
		isToken: true
	},
    method: 'post',
    data: data
  })
}

// 查询用户表单明细列表
export function getFormItemList(formKey) {
  return request({
    url: '/exam/front/class/listClassFormItem/' + formKey,
    method: 'get'
  })
}

// 新增报名入库
export function addFormItemData(data) {
  return request({
    url: '/exam/front/class/addFormItemData',
    method: 'post',
    data: data
  })
}

// 课程列表
export function courseMainList(query) {
    return request({
        url: '/exam/front/course/CourseMainList',
        method: 'get',
		params: query
    })
}














//以下测试后删除
export function home(data) {
	return request({
		url: '/pc/Courses/index',
		method: 'post',
		data

	})
}
//课程分类 || 二级

//课程分类 
export function keNavList(data) {
	return request({
		url: '/pc/Courses/cateList',
		method: 'post',
		data

	})
}
//专家列表
export function professorList(data) {
	return request({
		url: '/pc/Lecturers/getList',
		method: 'post',
		data

	})
}
//喜欢加列表详情
export function professorgetlist(data) {
	return request({
		url: '/pc/Lecturers/getDetail',
		method: 'post',
		data

	})
}
//院内课程
export function getHospitalCourseCate(data) {
	return request({
		url: '/pc/Mine/getHospitalCourseCate',
		method: 'post',
		data

	})
}
export function getHospitalCourses(data) {
	return request({
		url: '/pc/Mine/getHospitalCourses',
		method: 'post',
		data

	})
}

//课程二级
export function kechildList(data) {
	return request({
		url: '/pc/Courses/getList',
		method: 'post',
		data

	})
}


export function about(data) {
	return request({
		url: '/pc/About/getDetail',
		method: 'post',
		data

	})
}

//标签
export function tagAll(data) {
	return request({
		url: '/pc/Courses/getTags',
		method: 'post',
		data

	})
}
export function getListByTag(data) {
	return request({
		url: '/pc/Courses/getListByTag',
		method: 'post',
		data

	})
}

