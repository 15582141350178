<template>
	<div id="app">
		<Header v-if="categories.length>0"></Header>
		<!-- <keep-alive> -->
		<router-view v-if="categories.length>0" class="content"/>
		<!-- </keep-alive> -->
		<Footer></Footer>
		<LoginDialog />
	</div>
</template>
<script>
	import Header from "@/components/header.vue";
	import Footer from "@/components/footer.vue";

	import LoginDialog from './components/LoginDialog.vue';
	import {
		loadCategory,
		loadClassify

	} from "@/api/home";
	export default {
		components: {
			Header,
			Footer,
			LoginDialog
		},
		data() {
			return {
				//轮播left
				categories: [],

			};
		},
		created() {
			this.bodyScale();
			//岗位树
			this.getCategoryList();

		},
		methods: {

			bodyScale() {
				var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可视区域宽度
				console.log("devicewidth=" + devicewidth)
				var scale = devicewidth / 1920; // 除以设计稿的尺寸
				//document.body.style.zoom = scale; // 放大缩小相应倍数
			},
			//岗位分类树
			getCategoryList() {
				var parm = {
					classifyType: 'class'
				}
				loadClassify(parm).then(res => {
					console.log("loadCategory+++")
					// 保存到本地缓存
					localStorage.setItem('categories', JSON.stringify(res.data));
					this.categories = JSON.stringify(res.data);
				});
			},
		},

	}
</script>
<style lang="less">
	.content {
		margin-top:-29px;
		min-height: 100vh;
		
	}

</style>