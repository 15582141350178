<template>
  <div class="foot-box">
    <div class="footer auto-width">
      <div class="left">
        <ul class="clearfix option">
          <!--<li class="fl options" @click="$router.push('/aboutme')">关于我们</li>-->
		  <li class="fl options" >关于我们</li>
          <span class="fl options">&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
          <!--<li class="fl options"  @click="$router.push('/joinus')">加入我们</li>-->
		  <li class="fl options" >加入我们</li>
          <span class="fl options">&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
          <!--<li class="fl options" @click="$router.push('/contactus')" >联系我们</li>-->
		  <li class="fl options"  >联系我们</li>
          <span class="fl options">&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
             <li class="fl options" >友情链接</li>
			 <!--<li class="fl options" @click="jump">友情链接</li>-->.
          <span class="fl options">&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
          <li class="fl options" >手机版</li>
        </ul>
        <p class="footer_font">Copyright © 云南建筑技工学校版权所有   技术支持：兰州智嘉科技有限公司</p>
      </div>
      <div class="right">
        <div class="top">
         
          <h4>咨询电话：0871-65640266</h4>
        </div>
        <div class="min_right">
            <img :src="img" alt="" class="ewm">
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
     img: require("../assets/images/gzh.jpg"),
    }
  },
  methods:{
    jump(){
 window.open('https://www.baidu.com/',"_blank")
    },
  }
};
</script>
<style scoped lang='less'>
@import "../less/index.less";
.foot-box {
  background-color: @footer;
  
  @media (min-width: 768px) { /* 平板及以上 */
    .auto-width {
      max-width: 750px;
    }
  }
  
  @media (min-width: 1024px) { /* 桌面设备 */
    .auto-width {
      max-width: 1200px;
    }
  }
  
  .footer {
	
    width: 1500px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .left {
      .option {
        margin: 61px 0 52px 0;
        .options {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 25px;
          color: #999999;
          cursor: pointer;
        }
      }
      .footer_font {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 30px;
        color: #666666;
        padding-bottom: 33px;
      }
    }
    .right {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        // margin-top: 61px;
      .top {
      
        h4 {
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 30px;
          color: #666666;
          margin:20px;
        }
      }
      .min_right{
          .ewm{
              width: 100px;
              height: 100px;
			  border-radius: 3px;
          }
      }
    }
  }
}
</style>