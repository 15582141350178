<template>
	<div class="boxs">
		<div class="header auto-width">
			<div class="header-left">
				<img :src="logo" alt="Logo" class="logo" @click="switchTab('')" />
				<span class="head-title" @click="switchTab('')">云南省建筑技工学校培训业务服务平台</span>
				<ul class="option clearfix">
					<li @click="switchTab('')" :class="{ active: activeTab === '' }" class="fl options">首页</li>
					<!-- 动态渲染选项 -->
					<li v-for="(item, index) in visibleOptions" :key="index" @click="switchTab(item.classifyId)"
						:class="{ active: activeTab === item.classifyId }" class="fl options">{{ item.classifyName }}</li>
					<!-- 更多按钮 -->
					<li v-if="showMore" @click="showDropdownMenu = true"
						@mouseover="showDropdownMenu = true; calculateDropdownPosition()" class="fl options more-btn">
						更多<i class="el-icon-arrow-down"></i></li>
				</ul>
				<!-- 隐藏选项下拉菜单 -->
				<div style="z-index: 999999; position: absolute;" v-if="showMore && hiddenOptions.length > 0"
					v-show="showDropdownMenu" @mouseleave="showDropdownMenu = false" class="dropdown-menu1"
					:style="{ top: dropdownTop + 'px', left: dropdownLeft + 'px' }">
					<ul
						style="background-color: white;padding: 10px;width: 120px;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);">
						<li v-for="(item, index) in hiddenOptions" :key="index" @click="switchTab(item.classifyId)"
							:class="{ active: activeTab === item.classifyId }" class="menu-item">{{ item.classifyName }}</li>
					</ul>
				</div>
			</div>
			<div v-if="!nickname" class="logins">
				<span @click="login()" class="login">登录</span>
				<span class="login"> / </span>
				<span @click="$router.push('/register')" class="login">注册</span>
			</div>
			<div v-else class="logins">
				<span @mouseover="showDropdown = true" @mouseleave="showDropdown = false" class="user-info">
					<el-link :underline="false"
						style="font-size:16px;font-weight: 500 !important;">{{ nickname }}</el-link>
					<transition name="fade">
						<ul v-if="showDropdown" class="dropdown-menu">
							<li @click="$router.push('/personal')" class="menu-item">个人中心</li>
							<li @click="loginOut()" class="menu-item">退出登录</li>
						</ul>
					</transition>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';

	export default {

		data() {
			return {
				logo: require("../assets/images/logo.png"),
				showDropdown: false,
				showDropdownMenu: false, // 控制更多下拉菜单的显示与否
				activeTab: '', // 默认激活的选项卡索引
				optionItems: [
					/**
                { name: '首页', path: '/' },
                { name: '党校培训1' },
                { name: '取证培训2' },
                { name: '技能提升3' },
                { name: '职业资格4' },
                { name: '党校培训5' },
                { name: '取证培训6' },
                { name: '技能提升7' },
                { name: '职业资格8' },
                { name: '党校培训9' },
                { name: '取证培训10' },
                { name: '技能提升11' },
                { name: '职业资格12' },
                { name: '关于我们13', path: '/aboutme' }
				**/

				],

				visibleOptionsCount: 8, // 初始显示数量
				showMore: false, // 是否显示更多按钮
				hiddenOptions: [], // 隐藏的选项列表
				dropdownTop: 0,
				dropdownLeft: 0
			};
		},
		computed: {
			...mapGetters(['avatar', 'nickname']),
			visibleOptions() {
				return this.optionItems.slice(0, this.visibleOptionsCount);
			}
		},
		beforeMount() {
			window.addEventListener('resize', this.setVisibleNumber);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.setVisibleNumber);
		},
		mounted() {
			this.getCategoryList();
			this.setVisibleNumber();
		},
		created() {
			this.getCategoryList();
		},
		methods: {
			// 根据宽度计算设置显示栏数
			setVisibleNumber() {
				const width = document.body.getBoundingClientRect().width / 2;
				this.visibleOptionsCount = parseInt(width / 110);
				this.showMore = this.optionItems.length > this.visibleOptionsCount;
				this.hiddenOptions = this.optionItems.slice(this.visibleOptionsCount);
			},

			getCategoryList() {
				//从缓存中取
				this.optionItems = JSON.parse(localStorage.getItem('categories'));
			},
			//切换
			switchTab(categoryId) {
				this.activeTab = categoryId;
				if (categoryId == '') {
					this.$router.push({
						path: '/',
					})
				} else {
					this.$router.push({
						path: '/classList',
						query: {
							categoryId
						}
					})
				}

			},
			handleOptionClick(option) {
				if (option.path) {
					this.$router.push(option.path);
				}
				this.showDropdownMenu = false; // 点击选项后隐藏下拉菜单
			},
			goBackManager() {
				const url = "https://ynjz.ynsjzjx.cn/admin/";
				window.open(url, '_blank');
			},
			login() {
				this.$store.dispatch('user/setLoginDialogVisible', true);
			},
			loginOut() {
				this.$store.dispatch('user/LogOut').then(() => {
					setTimeout(() => {
						location.reload();
					}, 400);
				}).catch(err => {
					console.error(err);
				});
			},
			calculateDropdownPosition() {
				const moreBtn = document.querySelector('.more-btn');
				if (moreBtn) {
					const rect = moreBtn.getBoundingClientRect();
					this.dropdownTop = rect.bottom;
					this.dropdownLeft = rect.left;
				}
			}
		}
	};
</script>

<style scoped lang='less'>
	@import "../less/index.less";

	.boxs {
		background: @header;
		/**box-shadow: 0px 5px 30px rgba(51, 51, 51, 0.16);**/
		position: fixed;
		/* 添加固定定位 */
		top: 0;
		/* 固定在顶部 */
		left: 0;
		/* 从左侧开始 */
		right: 0;
		/* 到右侧结束 */
		z-index: 999;
		height: 90px;
		/* 设置较高的 z-index，确保在其他元素之上 */
	}

	@media (min-width: 768px) {

		/* 平板及以上 */
		.auto-width {
			max-width: 750px;
		}
	}

	@media (min-width: 1024px) {

		/* 桌面设备 */
		.auto-width {
			max-width: 1200px;
		}
	}

	.header {
		display: flex;
		justify-content: space-between;
		height: 89px;
		background-color: @header;
		margin: 0 auto;

		.header-left {
			display: flex;

			.logo {
				width: 74px;
				height: 60px;
				margin: 20px 0;
				cursor: pointer;
			}

			.head-title {
				color: #2e3092 !important;
				font-family: Arial, Helvetica, sans-serif;
				width: 150px;
				font-weight: 600;
				line-height: 25px;
				font-size: 16px !important;
				display: inline-block;
				text-align: center;
				word-wrap: break-word;
				overflow: hidden;
				margin: 20px 0;
			}

			.option {
				margin: auto 0;
				margin-left: 29px;

				.active {
					color: #0068dd !important;
					font-weight: bold;

					/* 当li有active类时，改变:after伪元素的背景色 */
					&:after {
						background-color: #0068dd;
						/* 选中时显示红线 */
					}
				}

				.options {
					cursor: pointer;
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 36px;
					color: #333333;
					margin-left: 36px;
					transition: color 0.3s;

					&:hover {
						color: #409eff;
					}
				}
			}

		}

		.logins {
			line-height: 88px;

			.user-info {
				position: relative;
				cursor: pointer;
			}

			.dropdown-menu {
				position: absolute;
				top: 15;
				right: -50;
				background-color: #fff;
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
				border: 1px solid #ddd;
				box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
				min-width: 120px;
				z-index: 100000;
				list-style: none;
			}

			.dropdown-menu1 {
				position: absolute;
				background-color: #fff;
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
				border: 1px solid #ddd;
				box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
				min-width: 120px;
				z-index: 100000 !important;
				list-style: none;
			}

			.menu-item {
				height: 60px;
				line-height: 60px;
				text-align: center;
				cursor: pointer;
				font-size: 14px;
				color: #333;
				white-space: nowrap;

				&:hover {
					background-color: #f5f5f5;
				}
			}

			.fade-enter-active,
			.fade-leave-active {
				transition: opacity 0.3s;
			}

			.fade-enter,
			.fade-leave-to {
				opacity: 0;
			}

			.login {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 31px;
				color: #333333;
				cursor: pointer;
			}
		}
	}


	.menu-item {
		height: 40px;
		line-height: 40px;
		text-align: center;
		cursor: pointer;
		font-size: 14px;
		color: #333;
		white-space: nowrap;

		&:hover {
			background-color: #f5f5f5;
		}
	}
</style>